export const FIGMA_URL = 'figma.com';
export const GOOGLE_DOCS_URL = 'docs.google.com/document';
export const GOOGLE_SHEETS_URL = 'docs.google.com/spreadsheets';
export const XERO_URL = 'xero.com';
export const PANDA_DOC_URL = 'pandadoc.com';

export const DDC_CONTACT_INFO = {
  name: 'Design in DC',
  address: '1101 Connecticut Ave NW,',
  apartment: 'Suite 450 #94',
  zip: 'Washington, DC 20036',
  phone: '+1 202-931-7858',
};
