import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import moment, { Moment } from 'moment';
import logo from '../../images/logo-black.jpg';
import { Detail, ProjectInvoiceProps } from '../../entities';
import { DDC_CONTACT_INFO } from '../../utils/constants';
import { displayHoursFromNumber, formatCurrency } from '../../common/utils';
import { formInvoiceFromDetailData } from '../../utils';
import { datePickerFormat } from '../../constants';

const styles = StyleSheet.create({
  page: { padding: 24, fontSize: 12 },
  section: { marginBottom: 24 },
  header: { flexDirection: 'row', justifyContent: 'space-between', marginBottom: 24 },
  logo: { height: 50 },
  title: { fontSize: 24, marginBottom: 12 },
  invoiceNumber: { fontSize: 18 },
  billingDetails: { flexDirection: 'row', justifyContent: 'space-between', marginBottom: 12 },
  tableHeader: { flexDirection: 'row', borderBottomWidth: 1, borderBottomColor: '#000', paddingBottom: 5, marginBottom: 5 },
  tableRow: { flexDirection: 'row', paddingBottom: 5, marginBottom: 5 },
  tableSummary: { flexDirection: 'row', borderTopWidth: 1, borderBottomWidth: 1, marginTop: 20, marginBottom: 25, paddingVertical: 5 },
  column: { width: '25%', textAlign: 'left', paddingRight: 10 },
  textRight: { textAlign: 'right', width: '25%', paddingRight: 10 },
});

type Props = ProjectInvoiceProps & { details?: Detail[] };

const PDFInvoice = ({ invoiceNumber, paidDate, invoiceTo, dateRange, dueDate, details, due }: Props) => {
  const formattedDate = (date: Moment | Date | string | null) => moment(date).format(datePickerFormat);

  const renderInvoiceHeader = () => (
    <View style={styles.header}>
      <Image style={styles.logo} src={logo} />
      <View>
        <Text style={styles.invoiceNumber}>#{invoiceNumber}</Text>
        <Text>{paidDate ? `Paid on: ${formattedDate(paidDate)}` : 'Not paid'}</Text>
      </View>
    </View>
  );

  const renderBillingInfo = () => (
    <View style={styles.billingDetails}>
      <View>
        <Text>Invoice From:</Text>
        {Object.values(DDC_CONTACT_INFO).map((info, index) => (
          <Text key={index}>{info}</Text>
        ))}
      </View>
      {invoiceTo && (
        <View>
          <Text>Invoice To:</Text>
          {Object.values(invoiceTo).map((info, index) => (
            <Text key={index}>{info}</Text>
          ))}
        </View>
      )}
    </View>
  );

  const renderBillingDetails = () => (
    <View style={styles.billingDetails}>
      <View>
        <Text>Billing Range:</Text>
        {dateRange && <Text>{dateRange.map(formattedDate).join(' - ')}</Text>}
      </View>
      <View>
        <Text>Due Date:</Text>
        <Text>{dueDate ? formattedDate(dueDate) : 'N/A'}</Text>
      </View>
    </View>
  );

  const renderTableHeader = (title: string) => (
    <View style={styles.tableHeader}>
      <Text style={styles.column}>{title}</Text>
      <Text style={styles.textRight}>Billable Hours</Text>
      <Text style={styles.textRight}>Billable Rate</Text>
      <Text style={styles.textRight}>Billable Total</Text>
    </View>
  );

  const renderTableSummary = (totalBillableHours: number, totalBillableTotal: number) => (
    <View style={styles.tableSummary}>
      <Text style={styles.column}>Total bill</Text>
      <Text style={styles.textRight}>{displayHoursFromNumber(totalBillableHours)}</Text>
      <Text style={styles.textRight}></Text>
      <Text style={styles.textRight}>{formatCurrency(totalBillableTotal)}</Text>
    </View>
  );

  const renderTableRows = (groupedDetails: Omit<Detail, 'role' | 'phase'>[]) =>
    groupedDetails.map(groupedDetail => (
      <View key={groupedDetail.key} style={styles.tableRow}>
        <Text style={styles.column}>{groupedDetail.key}</Text>
        <Text style={styles.textRight}>{displayHoursFromNumber(groupedDetail.billableHours)}</Text>
        <Text style={styles.textRight}>{formatCurrency(groupedDetail.billableRate)}</Text>
        <Text style={styles.textRight}>{formatCurrency(groupedDetail.billableTotal)}</Text>
      </View>
    ));

  const groupedDetailsByRole = formInvoiceFromDetailData('role', details);
  const groupedDetailsByPhase = formInvoiceFromDetailData('phase', details);

  const totalBillableHours = groupedDetailsByRole.reduce((sum, { billableHours }) => sum + billableHours, 0);
  const totalBillableTotal = groupedDetailsByRole.reduce((sum, { billableTotal }) => sum + billableTotal, 0);

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {renderInvoiceHeader()}
        <View style={styles.section}>
          <Text style={styles.title}>{due}</Text>
          {renderBillingInfo()}
        </View>
        {renderBillingDetails()}
        <View style={styles.section}>
          {renderTableHeader('Role')}
          {renderTableRows(groupedDetailsByRole)}
          {renderTableSummary(totalBillableHours, totalBillableTotal)}
          {renderTableHeader('Phase')}
          {renderTableRows(groupedDetailsByPhase)}
        </View>
      </Page>
    </Document>
  );
};

export default PDFInvoice;
